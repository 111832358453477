import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import YouTube  from "react-youtube";

const MainSlider = () => {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    }

    const subsequent_opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    }



    return <div className="main_slider">
        <Carousel showIndicators={true}>
            {/* <div>
              <img src="https://i.scdn.co/image/ab67616d0000b273a01a1a2f5e3c04c48ddfac0e"/>
              <div>Latest approved Spotify playlist submission from KhyKush. Check him out on Spotify. Submit your best track below.</div>
            </div> */}
            <div>
                <YouTube opts={opts} videoId="1DUDAaI9KDY" autoplay={true}/>
                {/* <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1711488600&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/vigobeatz" title="vigobeatz" target="_blank" style="color: #cccccc; text-decoration: none;">vigobeatz</a> · <a href="https://soundcloud.com/vigobeatz/time" title="Time" target="_blank" style="color: #cccccc; text-decoration: none;">Time</a></div> */}
                <iframe
                  id="sound-cloud-iframe"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1711488600&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  style={{width: '80%', height: '100%'}}
                >
                </iframe>
                {/* <SoundCloud url="https://soundcloud.com/vigobeatz/time?si=801350052fc94d48a9a8e633352e90c0&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"/> */}
                {/* <YouTube opts={opts} videoId="ixi8PUH8qu4" autoplay={false}/> */}
                {/* <iframe width="100%" height="300" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1711488600&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{fontSize: 10, color: '#cccccc',lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis',fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",fontWeight: 100}}><a href="https://soundcloud.com/vigobeatz" title="vigobeatz" target="_blank" style="color: #cccccc; text-decoration: none;">vigobeatz</a> · <a href="https://soundcloud.com/vigobeatz/time" title="Time" target="_blank" style="color: #cccccc; text-decoration: none;">Time</a></div> */}
                {/* https://www.youtube.com/watch?v=1DUDAaI9KDY */}
            </div>
        </Carousel>
    
    </div>
}

export default MainSlider