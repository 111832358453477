import './nosuchlink.css'

const NoSuchLink = () => {
    return <div className="no_such_link">
        <span className="promo_is_closed">
            Promotion Closed
        </span><br/><br/>
        <p>
            If you are getting this message, that probably means that the promotional period for participating on the song is now over. Please keep in tune by subscribing on youtube and when a new song drops if you reach the link earlier next time you might get to be in the final release. Don't be discouraged, we do these often on YouTube and other platforms.
        </p>
    </div>
}

export default NoSuchLink