import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

// const config = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// };


// const config = {
//   apiKey : process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId : process.env.REACT_APP_MEASUREMENT_ID
// }

const config = {
  apiKey: "AIzaSyAUALVOgWyufbrRpLzrZoTuce8z9M5_BPs",
  authDomain: "trapppcloud.firebaseapp.com",
  projectId: "trapppcloud",
  storageBucket: "trapppcloud.appspot.com",
  messagingSenderId: "674490596107",
  appId: "1:674490596107:web:5ae0d05e1c49760df6400e",
  measurementId: "G-EJGMXYPBYE"
};

export const app = initializeApp(config)
export const firestore = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app,config.storageBucket)
