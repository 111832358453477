import './drop.css'
import { UploadFile } from '@mui/icons-material'
const Drop = () => {
    const onDragOver = (e:any) => {
        let event = e as Event;
        event.preventDefault();
        event.stopPropagation();
      }
    
    const onDragEnter = (e:any) => {
        let event = e as Event;
        event.preventDefault();
        event.stopPropagation();
      }
    
    const onFileDrop = (e:any) => {
        let event = e as Event;
        event.preventDefault();
        event.stopPropagation();
    
        console.log("onFileDrop");
        alert("dropped")
      }

    return <div onDrop={onFileDrop} onDragEnter={onDragEnter} onDragOver={onDragOver} className="drop">
        <UploadFile/><br/>
        Drop Your MP3s Here.
        No WAV or AIFF or anything that's not an MP3 file
    </div>
}

export default Drop