import {   FacebookShareButton } from 'react-share'
import { Facebook } from '@mui/icons-material'

const ShareUs = () => {
    return <></>
    // return <FacebookShareButton style={{alignSelf: 'center'}} hashtag={"saucepantvdotnet"} url={"https://www.saucepantv.net"}>
    //     <button><Facebook style={{position: 'relative',top: 2}}/><span style={{position: 'relative', top: -5}}>Tell Your Friends</span></button>
    // </FacebookShareButton>
}

export default ShareUs