import Header from '../../Components/Header/Header'
import SignIn from '../../Components/SignIn/SignIn'

const Login = (props:any) => {
    return <div>
        <Header/>
        <SignIn/>
    </div>
}

export default Login