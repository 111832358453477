import { useState, useEffect, useCallback, KeyboardEventHandler } from 'react'
import './discover.css'
import Header from '../../Components/Header/Header'
import { firestore } from '../../firebase'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { Verified } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import AsideMenu from '../../Components/AsideMenu/AsideMenu'
type SearchResults =  {
    isArtist : boolean,
    isSong : boolean,
    isAlbum : boolean,
    name : string
    link : string
    image : string
    followers : number
    verified : boolean
}

const Discover = () => {
    const [discovered,setDiscovered] = useState<SearchResults[]>([])
    const retrieveDiscoveryResults = useCallback(()=>{
        
    },[setDiscovered])
    

    /*
        results

       
    */
    const performSearch = async (e:any) => {
        if(e.target.value.length > 0){
            // Check to see if there's any artists with that name
            const artistsCollection = collection(firestore,'users');
            const artistQuery = query(artistsCollection,where("username",">=",e.target.value))
            const matchingArtistDocs = await getDocs(artistQuery)

            // Go through all the matching artists and generate a result item for each
            let artistMatchResults:SearchResults[] = []
            matchingArtistDocs.docs.forEach(doc=>{
                console.log(doc)
                artistMatchResults.push({
                    isArtist : true,
                    isSong : false,
                    isAlbum : false,
                    name : doc.data().username ?? "Unknown",
                    link : 'https://saucepantv.net/profiles/' + doc.data().username,
                    verified : doc.data().verified ?? false,
                    image : doc.data().profile_image,
                    followers : doc.data().follower_count ?? 0
                })
            })

            // Check to see if there's any songs with that name
            let songMatchResults:SearchResults[] = []
            const allArtistsQuery = query(artistsCollection)
            const allArtistsDocs = await getDocs(allArtistsQuery)
            allArtistsDocs.docs.forEach(async doc=>{
                const artistId = doc.id
                // Query all the artists songs
                const artistSongsCollection = collection(firestore,'users/' + artistId + "/feed")
                const artistSongNameMatchQuery = query(artistSongsCollection,where("name",">=",e.target.value))
                const artistSongsDocs = await getDocs(artistSongNameMatchQuery)
                artistSongsDocs.docs.forEach(song=>{
                    songMatchResults.push({
                        isArtist : false,
                        isSong : true,
                        isAlbum : false,
                        name : song.data().name,
                        link : 'https://saucepantv.net/profiles/' + doc.data().username,
                        verified : doc.data().verified ?? false,
                        image : song.data().coverUrl,
                        followers : doc.data().follower_count ?? 0
                    })
                })
            })    
            
            setDiscovered([...songMatchResults,...artistMatchResults])

            // Check to see if there's any albums with that name
            
            // setDiscovered(artistMatchResults)
            // console.log(artistMatchResults)
        }else{
            setDiscovered([])
        }

      

    }

    const navigate = useNavigate()

    return <div>
        <Header/>
        <div className="discover">
            <AsideMenu/>
            <div>
                <input type="text" placeholder="Search artists or songs by name" className="search_box" onChange={performSearch}/>
                {discovered.map(item=>{
                    return <a href={item.link} style={{textDecoration : 'none', color: 'inherit'}}><div className="search_result" key={item.name}>
                        <span className="search_results_kind">
                            {item.isAlbum ? "Album" : ""}
                            {item.isArtist ? "Artist" : ""}
                            {item.isSong ? "Song" : ""}
                        </span>
                        <img src={item.image} className="search_result_thumbnail"/>
                        <span className="search_results_username">
                            {item.name}
                        </span>
                        <span className="search_results_verified">
                            {item.verified ? <Verified htmlColor="rgb(117, 89, 157)"/> : <></>}
                        </span>
                        <span className="search_results_followers">
                            Followers: {item.followers ?? 0} 
                        </span>
                    </div></a>
                })}
            </div>
        </div>
    </div>
}

export default Discover