import Header from "../../Components/Header/Header"
import { useNavigate } from "react-router-dom"
const Admin = () => {
    const navigate = useNavigate()

    return <>
        <Header/>
        <button onClick={e=>navigate('/manageusers')}>
            Manage Users
        </button>
        <button>
            Manage News
        </button>
        <button>
            Manage Featured Section
        </button>
    </> 
}  

export default Admin