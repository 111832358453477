import { useNavigate } from "react-router-dom"
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material'
import './TermsAndConditions.css'

const TermsAndConditions = ({open,setOpen}:any) => {
    const navigate = useNavigate()

    return <Dialog open={open} onClose={e=>setOpen(false)}>
        <DialogContent>
            <div className="terms_and_conditions_content">
                {/* <button onClick={e=>navigate(-1)}>Back</button> */}
                <h3>SaucePanTv.net Terms and Conditions:</h3>

                <h5>I. Liability</h5>

                <Typography>
                    You agree to absolve sucepantv.net from any liability that may arise from your use of the platform. You agree that all content provided by you
                    is owned by you and nobody else. And that you hold the copyrights to all materials shared on the platform. If any dispute were to be raised
                    about the validity of your copyright we will not be held liable for your copyright infringement.
                </Typography>
                    
                <h5>II. Respect</h5>

                <Typography>You are expected to treat everyone in the platform with respect. Any use of hate speech or threatning language will be grounds for immediate
                    termination from our platform. Any remaining StarDust or in app assets will be immediately destroyed and you will not be refunded. 
                    If you have any problems with anybody being disrespectful you are encouraged to report them using the reporting tool.
                </Typography>

                <h5>III. Collaboration</h5>

                <Typography>All media, intellectual property and/or data generated by any party in the platform remains the property of that party. Even if samples, project files or stems are shared, they remain the property of the original owner unless otherwise specified by legal documentation.</Typography>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={e=>setOpen(false)}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default TermsAndConditions