import Header from "../../Components/Header/Header"
import { firestore } from "../../firebase"
import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Visibility } from "@mui/icons-material"
import "./manage_users.css"

const ManageUsers = () => {
    const [users,setUsers] = useState<any[]>([])

    useEffect(()=>{
        
        const getUsers = async () => {
            const usersCollection = collection(firestore,'/users')
            const userDocs = await getDocs(usersCollection)
            let tmpusers:any[] = []
            userDocs.forEach(usr=>{
               tmpusers.push(usr.data())
            })
            setUsers(tmpusers)
        }

        getUsers()
    },[setUsers])

    return <>
        <Header/>
        Manage Users
        {users && users.map(user=><div className="user_item" key={user.uid}>
            {user.username}
            <a href={'/profiles/' + user.username}><Visibility/></a>
        </div>)}
    </>
}

export default ManageUsers