import AsideMenu from '../../Components/AsideMenu/AsideMenu'
import './news.css'
import Header from '../../Components/Header/Header'
import NewsComponent from '../../Components/News/News'
const News = ( ) => {
    return <div>
        <Header/>
        <div className="news_container">
            <div className="aside_menu">
                <AsideMenu/>
            </div>
            <main className="news_container_main">
                <NewsComponent direction="horizontal"/>
            </main>
        </div>
    </div>
}

export default News