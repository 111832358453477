import './telling_you_bitches.css'

const TellingYouBitches = () => {
    return <div className="telling_you_bitches">
        <div className="title">
            Telling You Bitches YouTube Collab Challenge
        </div>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/vm0JknBz7JQ?si=15EJRAU7gv-Vn6Uk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <p style={{padding: 20}}>
            So first, download the mp3 and record all your vocals using any DAW of your choice. Leave the vocals clean. Any vocals with effects will be rejected. I will do all the mixing and mastering for the final version. Once you have the vocal stems, put them in a zip folder and hit the upload button. Fill out the details in the upload form and then upload the files. If you're selected you will receive an email. Since there's going to be 4 people on this track, the writing credits will be split 4 ways, that's 1/4 or 25% writers credits. And since I produced the song, I'll take the production credits.
        </p>
        <button style={{marginTop: 10}}>Download MP3</button>
        <button style={{marginTop: 10}}>Upload Verse Vocals</button>
    </div>
}

export default TellingYouBitches