import { useParams } from 'react-router-dom'
import TellingYouBitches from './Promos/TellingYouBitches';
import NoSuchLink from './Promos/NoSuchLink';

const CookUps = () => {
    const params = useParams();

    const titleToCookupPromoMapper = (title:string | any) => {
        switch(title){
            case 'telling_you_bitches':
                return <TellingYouBitches/>
            default : 
                return <NoSuchLink/>
        }
    }


    return <div className="cookups">
        {titleToCookupPromoMapper(params.title)}
    </div>
}

export default CookUps