import './arena.css'
import { useState, useCallback, useEffect } from 'react'
import { auth, firestore } from '../../firebase'
import Submitter from './submitter'
import Drop from './drop'
import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
import { ArrowRight, AudioFile } from '@mui/icons-material'
import Header from '../../Components/Header/Header'
import SongCoverEditor from '../Profile/SongCoverEditor/SongCoverEditor'
import AudioPlayer from 'react-h5-audio-player'
import { addDoc, collection, doc, increment, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import PlayCounter from '../Profile/PlayCounter/PlayCounter'
import Likes from '../Profile/Likes/Likes'
import CommentCounter from '../Profile/CommentCounter/CommentCounter'
import SongCommentsComponent from '../Profile/SongCommentsComponent/SongCommentsComponent'
import { useLocation, useNavigate } from 'react-router-dom'

type SubmitterType = {
    link : string,
    uid : string,
    profile_image : string,
    username : string
}

const containerStyle = {
    width: '100%',
    height: '400px'
};
  
const center = {
    zoom: 4,
    lat: 40.08591237093346,
    lng: -99.08801909298705
};

const Arena = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAUALVOgWyufbrRpLzrZoTuce8z9M5_BPs"
    })

    const [user,setUser] = useState<any>(null)
    const [map, setMap] = useState<any>(null)

    const onLoad = useCallback(function callback(map: { fitBounds: (arg0: google.maps.LatLngBounds) => void }) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const [submitters,setSubmitters] = useState<SubmitterType[]>([
        {
            link : "http://google.com",
            uid : "ABC123",
            profile_image : "https://firebasestorage.googleapis.com/v0/b/trapppcloud.appspot.com/o/0ZRnb5LVYBUVcCnOIR6w19aXU4q2%2Fprofile_pictures%2FIMG-0189_2.jpg?alt=media&token=98a3f1c8-eb52-48bd-b3e7-2106361b5756",
            username : "PurpaLicious_0"
        },
        {
            link : "http://google.com",
            uid : "ABC321",
            profile_image : "https://firebasestorage.googleapis.com/v0/b/trapppcloud.appspot.com/o/0ZRnb5LVYBUVcCnOIR6w19aXU4q2%2Fprofile_pictures%2FIMG-0189_2.jpg?alt=media&token=98a3f1c8-eb52-48bd-b3e7-2106361b5756",
            username : "PurpaLicious_1"
        },
    ])

    const [nowPlaying,setNowPlaying] = useState<SubmitterType>({
        link : "http://google.com",
        uid : "ABC132",
        profile_image : "https://firebasestorage.googleapis.com/v0/b/trapppcloud.appspot.com/o/0ZRnb5LVYBUVcCnOIR6w19aXU4q2%2Fprofile_pictures%2FIMG-0189_2.jpg?alt=media&token=98a3f1c8-eb52-48bd-b3e7-2106361b5756",
        username : "PurpaLicious_0"
    })

    const [songs,setSongs] = useState([])

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(()=>{
        auth.onAuthStateChanged(usr=>{
            if(user != null) {
                setUser(usr)
                // Only when the user is logged in will sharing a song in the battle arena be allowed
                const { song } = location.state
                console.log("Incoming song: ")
                console.log(song)
            }else{
                // navigate('/login')
            }
        })
        setTimeout(()=>{
            // map.zoom = 5
        },1000)
    })

    const registerPlay = async (e:any,song:any) => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data.IPv4)
        // console.log(song.id)

        //Add song entry to the user's playlog for processing later
        const profileOwnerPlayLogRef = collection(firestore,'/users/' + song.owner.uid + '/playlog')
        
        //Remove the song comments before adding the document
        let sng_cpy = Object.assign({},song)
        delete sng_cpy.comments
        
        // if(songsPlayed.indexOf(songs) === -1){ //If this song has not been played yet then increment the playcount by one
        //Save the documment
        await addDoc(profileOwnerPlayLogRef,{
            user : user.uid,
            ip : res.data.IPv4, 
            song_id : song.id,
            song : sng_cpy,
            timestamp : new Date(),
        })  

        //Update the song's playcount
        const songDocRef = doc(firestore,'users/' + song.owner.uid + '/songs/' + song.id)
        await updateDoc(songDocRef,{
            plays : increment(1)
        }) 

        //Update the overall play counter and weekly counters
        const ownerProfileRef = doc(firestore,'/users/' + song.owner.uid)
        await updateDoc(ownerProfileRef,{
            overall_plays : increment(1),
            weekly_plays : increment(1)
        })
    }

    return <>
        <Header/>
        <div className="main_arena_map">
            {isLoaded && (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={4}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >                
                {songs.map((s:any,i:any)=><Marker key={s} position={{lat: 33.8151925946832, lng: -84.51284156252486}}>
                    <InfoWindow>
                    <div className="song">
                        <img alt="song_cover" className="song_cover" src={s.coverUrl} onClick={e=>{}}/>
                        <AudioPlayer
                            className="song_player"
                            autoPlay={false}
                            src={s.downloadURL}
                            onPlay={async e => await registerPlay(e,s)}
                            // other props here
                        />
                        <div className="song_info">
                            <div className="songName">
                                <AudioFile/><span style={{position: 'relative', top: '-5px', left: 0}}>{s.name.length > 40 ? s.name.substring(0,40) + '...' : s.name}</span>
                            </div>
                            <div className="playCounter"><PlayCounter song={s}/></div>                                    
                            <div className="songLikes"><Likes owner={s.owner.uid} song={s} user={user}/></div>
                            <div className="commentCounter"><CommentCounter song={s}/></div>
                            <div className="songComments">
                                <SongCommentsComponent owner={s.owner.uid} song={s} user={user}/>
                            </div>
                        </div>
                    </div> 
                    </InfoWindow>
                </Marker>)}
            </GoogleMap>)}
        </div>
        
        <div className="player_and_drop">
            <div className="music_player">
                <div>
                    Now Playing <ArrowRight style={{position : 'relative', top:  7}}/> {nowPlaying.link}<br/><br/>
                </div>
                <div className="player">
                    <Submitter submitter={nowPlaying}/>
                    <audio controls src={""}>
                        <source src="horse.ogg" type="audio/ogg"/>
                        <source src="horse.mp3" type="audio/mpeg"/>
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
            <Drop/>
        </div>
        Submission Queue<br/>
        <div className="submitter_queue">
            UP NEXT <ArrowRight/>  {submitters.map(submitter=><Submitter key={submitter.uid} submitter={submitter}/>)}
        </div>  
    </>
}


export default Arena