import './submitter.css'
type SubmitterType = {
    link : string,
    uid : string,
    profile_image : string,
    username : string
}

const Submitter = ({submitter}:{submitter: SubmitterType}) => {
    return <div className="submitter_container">
        <img width={50} height={50} src={submitter.profile_image} className="submitter_image"/><br/>
        <div className="submitter_username">
            {submitter.username}
        </div>
    </div>
}

export default Submitter